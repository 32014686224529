/**
 * @file It contains scripts for File Component.
 */
export default {
  name : "File",
  props: {
    pFile          : Object,
    pIsDownloadable: Boolean
  },
  computed: {
    fileSize() {
      let size
      if(this.pIsDownloadable) {
        size = this.pFile.value.size / 1024
      } else {
        size = this.pFile.size / 1024
      }
      if (size > 1024) {
        size = `${Math.floor(size / 1024)} MB`
      } else {
        size = `${Math.floor(size)} KB`
      }
      return size
    }
  }
}